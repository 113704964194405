import "./Paytable.scss";

import React from "react";
import { Row, Col } from "react-bootstrap";

const Paytable = () => {
	return (
		<div className="paytable">
			<div className="paytable--content">
				<div className="paytable--content-title">Paytable</div>
				<div className="paytable--content-container">
					<Row>
						<Col>
							<div className="paytable--content-container-subtitle mt5">
								Any Card Drawn - <span>10/1</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-block">
								Ace - King
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-subtitle">
								Any Suit Drawn - <span>5/2</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-block">
								Hearts
							</div>
						</Col>
						<Col>
							<div className="paytable--content-container-block">
								Diamonds
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-block">
								Spades
							</div>
						</Col>
						<Col>
							<div className="paytable--content-container-block">
								Clubs
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-subtitle">
								Specific Card Drawn - <span>45/1</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-block">
								Suit + Number or Picture
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-subtitle">
								Picture - <span>5/2</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-block">
								Jack
							</div>
						</Col>
						<Col>
							<div className="paytable--content-container-block">
								Queen
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-block">
								King
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-subtitle">
								Number - <span>2/10</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-block">
								Ace - 10
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-subtitle">
								Colors - <span>1/1</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-block red">
								Red
							</div>
						</Col>
						<Col>
							<div className="paytable--content-container-block black">
								Black
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
};

export default Paytable;
