import React, { useRef, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Header from "./components/Header";
import Paytable from "./components/Paytable";
import History from "./components/History";

import useDeviceWidth from "./components/hooks/useDeviceWidth";

import moment from "moment";
import Fullscreen from "react-full-screen";

import "./static/styles/base.scss";
import "./MegaDraw.scss";

const MegaDraw = () => {
	const [gameOnly, setGameOnly] = useState(false);
	const [isFullScreen, setIsFullScreen] = useState(false);
	const canvas = useRef();

	const [currentDraw, setCurrentDraw] = useState("----");

	const [timerMinutes, setTimerMinutes] = useState("00");
	const [timerSeconds, setTimerSeconds] = useState("00");

	const [hotNumbers, setHotNumbers] = useState([]);
	const [coldNumbers, setColdNumbers] = useState([]);

	const [history, setHistory] = useState([]);
	let hotNumbersUpdate, coldNumbersUpdate, historyUpdate, drawIdUpdate;

	const [animationHeight, setAnimationHeight] = useState(0);
	const [deviceWidth] = useDeviceWidth();
	const [canResize, setCanResize] = useState(false);

	let hasRetry = false;

	let timerInterval;

	const socketConnect = () => {
		//Dev
		// let socket = new WebSocket(
		// 	"wss://jnofm494n6.execute-api.eu-west-2.amazonaws.com/dev?feed_id=2"
		// );

		let socket = new WebSocket(
			"wss://7hqboxr05l.execute-api.eu-west-2.amazonaws.com/live?feed_id=2"
		);

		socket.onopen = function(event) {};

		socket.onmessage = function(event) {
			socketMessage(event, socket);
		};

		socket.onclose = function(event) {
			socketRetryConnection();
		};
	};

	const socketRetryConnection = () => {
		startTimer(0);

		if (!hasRetry) {
			hasRetry = true;
			canvas.current.contentDocument.closeCards();
		}

		setTimeout(function() {
			if (!canvas.current.contentDocument.isFlipping()) {
				socketConnect();
			} else {
				socketRetryConnection();
			}
		}, 5000);
	};

	const socketMessage = (event, socket) => {
		let useData = event.data;

		if (useData) {
			useData = JSON.parse(useData);

			let nextDraw = formatDate(useData.nextDraw, useData.currentTime);

			hotNumbersUpdate = useData.hotNumbers;
			coldNumbersUpdate = useData.coldNumbers;
			historyUpdate = useData.history;
			drawIdUpdate = useData.nextDrawId;

			historyUpdate.push(useData.latestResult);

			startTimer(nextDraw);

			if (useData.initial === "True") {
				setHotNumbers(hotNumbersUpdate);
				setColdNumbers(coldNumbersUpdate);
				setHistory(historyUpdate);
				setCurrentDraw(drawIdUpdate);

				if (hasRetry) {
					hasRetry = false;
					canvas.current.contentDocument.openCards(nextDraw);
				} else {
					canvas.current.contentDocument.loader(nextDraw);
				}
			} else {
				canvas.current.contentDocument.flipCard(
					parseInt(useData.latestResult.result),
					nextDraw
				);
			}
		} else {
			socket.close();
		}
	};

	const formatDate = (nextDraw, currentTime) => {
		nextDraw = moment(nextDraw);
		var duration = moment.duration(nextDraw.diff(moment(currentTime)));
		var tillNextDraw = duration.asSeconds();

		return tillNextDraw;
	};

	const startTimer = (duration) => {
		var timer = duration,
			minutes,
			seconds;

		clearInterval(timerInterval);

		timerInterval = setInterval(function() {
			minutes = parseInt(timer / 60, 10);
			seconds = parseInt(timer % 60, 10);

			minutes = minutes < 10 ? "0" + minutes : minutes;
			seconds = seconds < 10 ? "0" + seconds : seconds;

			setTimerMinutes(minutes);
			setTimerSeconds(seconds);

			if (--timer < 0) {
				timer = 0;
			}

			if (canvas.current.contentWindow.isDone) {
				setCurrentDraw(drawIdUpdate);

				setHotNumbers(hotNumbersUpdate);
				setColdNumbers(coldNumbersUpdate);

				setHistory(historyUpdate);
				canvas.current.contentDocument.checkedDone();
			}
		}, 1000);
	};

	const getColor = (number) => {
		if (number <= 26) {
			return "red";
		} else {
			return "black";
		}
	};

	const getCard = (number) => {
		let cardDescription = [
			"A",
			"2",
			"3",
			"4",
			"5",
			"6",
			"7",
			"8",
			"9",
			"10",
			"J",
			"Q",
			"K",
			"A",
			"2",
			"3",
			"4",
			"5",
			"6",
			"7",
			"8",
			"9",
			"10",
			"J",
			"Q",
			"K",
			"A",
			"2",
			"3",
			"4",
			"5",
			"6",
			"7",
			"8",
			"9",
			"10",
			"J",
			"Q",
			"K",
			"A",
			"2",
			"3",
			"4",
			"5",
			"6",
			"7",
			"8",
			"9",
			"10",
			"J",
			"Q",
			"K",
		];

		return cardDescription[number - 1];
	};

	const getSuit = (number) => {
		if (number <= 13) {
			return "hearts";
		} else if (number >= 14 && number <= 26) {
			return "diamonds";
		} else if (number >= 27 && number <= 39) {
			return "spades";
		} else {
			return "clubs";
		}
	};

	const fullScreenToggler = () => {
		setIsFullScreen(!isFullScreen);
	};

	useEffect(() => {
		const windowUrl = window.location.search;
		const params = new URLSearchParams(windowUrl);

		if (params.get("gameOnly")) {
			setGameOnly(true);
		}

		window.canStart = (height) => {
			setAnimationHeight(height);
			setCanResize(true);

			socketConnect();
		};

		document.addEventListener(
			"contextmenu",
			function(e) {
				e.preventDefault();
			},
			false
		);
	}, []);

	useEffect(() => {
		if (canvas.current && canResize) {
			setAnimationHeight(canvas.current.contentDocument.getHeight());
		}
	}, [deviceWidth]);

	return (
		<Fullscreen
			enabled={isFullScreen}
			onChange={(isFullScreen) => setIsFullScreen(isFullScreen)}
		>
			<div className="megawinner">
				<Header
					currentDraw={currentDraw}
					timerMinutes={timerMinutes}
					timerSeconds={timerSeconds}
					fullScreenToggler={fullScreenToggler}
				/>
				<div className="megawinner--container">
					<Row noGutters>
						{!gameOnly ? (
							<Col
								xl={{ order: "first" }}
								lg={{ order: "last" }}
								sm="6"
							>
								<Paytable />
							</Col>
						) : (
							""
						)}

						<Col
							xl={!gameOnly ? 7 : 12}
							lg={!gameOnly ? 8 : 12}
							xs={{ order: "first", span: 12 }}
						>
							<div className="megawinner--container-animation">
								<iframe
									src="/animation/animation.html"
									width="100%"
									height={animationHeight}
									ref={canvas}
									title="animation"
								></iframe>
							</div>
						</Col>

						{!gameOnly ? (
							<Col xl lg={4} sm="6">
								<History
									history={history}
									hotNumbers={hotNumbers}
									coldNumbers={coldNumbers}
									getColor={getColor}
									getCard={getCard}
									getSuit={getSuit}
								/>
							</Col>
						) : (
							""
						)}
					</Row>
				</div>
			</div>
		</Fullscreen>
	);
};

export default MegaDraw;
